<template>
  <div class="code-search">
    <CRow style="margin: auto; max-width: 400px">
      <CCol sm="8">
        <input
          class="form-control"
          placeholder="Code here"
          type="text"
          v-model="search"
        />
      </CCol>
      <CCol sm="4">
        <div>
          <button @click="searchCard">Search</button>
        </div>
      </CCol>
    </CRow>
    <div class="code-search-container">
      <div v-if="searching">
        <div style="text-align: center; padding-top: 110px; color: gray">
          <div>Searching...</div>
          <div>
            <CSpinner color="primary" variant="grow" style="width:18px;height:18px;padding-right:18px" />
            <CSpinner color="warning" variant="grow" style="width:18px;height:18px;padding-right:18px"/>
            <CSpinner color="danger" variant="grow" style="width:18px;height:18px;padding-right:18px"/>
            <CSpinner color="dark" variant="grow" style="width:18px;height:18px;padding-right:18px"/>
          </div>
        </div>
      </div>
      <div v-else>
        <div class="container" id="card" v-if="this.card">
          <div
            style="
              width: 84px;
              position: absolute;
              top: 4px;
              color: #fff;
              border-radius: 6px;
              padding: 4px;
              left: -16px;
              display: block;
              font-size: 22px;
              font-weight: 100;
            "
            v-if="this.card.is_vip"
          >
            VIP
          </div>

          <div
            style="
              width: 84px;
              position: absolute;
              top: 4px;
              color: #fff;
              border-radius: 6px;
              padding: 4px;
              right: 4px;
              display: block;
              font-size: 22px;
              font-weight: 100;
            "
          >
            Hyphen
          </div>
          <div class="type">{{ this.card.subscription }}</div>
          <div class="card-bottom" style="text-align: left">
            <span class="name"> {{ this.card.name }} </span>

            <div class="card-bottom-details">
              <div>
                <span class="card-details-title"> Valid </span> <br />
                {{ this.card.valid }}
              </div>
              <div>
                <span class="card-details-title"> Expires </span>
                <br />
                {{ this.card.expires }}
              </div>
              <div>
                <span class="card-details-title"> Code </span>
                <br />
                {{ this.card.code }}
              </div>
            </div>
            <img
              src="https://plentyofthings.com/en/plentywhitetext.png"
              alt=""
              style="
                width: 38px;
                position: absolute;
                bottom: 10px;
                right: 12px;
                display: block;
              "
              srcset=""
            />
          </div>
        </div>
        <h6 v-else style="text-align: center; padding-top: 110px; color: gray">
          Please Enter the code first!
        </h6>
      </div>
    </div>
  </div>
</template>

<script>
import { hyphenCardDetails } from "@/services/apis/hyphen";
export default {
  data() {
    return {
      search: "",
      card: null,
      searching: false,
    };
  },
  methods: {
    searchCard() {
      if (this.search && this.search.trim().length) {
        this.searching = true;
        hyphenCardDetails(this.search)
          .then((res) => {
            console.log(res.data);
            this.card = res.data;
          })
          .catch((err) => {
            this.card = null;
          })
          .finally(() => {
            this.searching = false;
          });
      }
    },
  },
};
</script>

<style scoped>
.code-search button {
  background-color: #000;
  border: none;
  outline: none;
  width: 100%;
  color: #fff;
  padding: 14px;
  margin-top: 8px;
  border-radius: 4px;
}
.code-search input {
  text-align: center;
}
.code-search-container {
  background-color: #fafafa;
  min-height: 280px;
  margin-top: 18px;
  padding-top: 22px;

  border-radius: 12px;
}

.container {
  width: 280px;
  height: 158px;
  margin: auto;
  text-align: center;
  background-color: #000;
  padding: 34px 14px;
  border-radius: 12px;
  margin-top: 48px;
  color: #fff;
  font-family: "Genos", sans-serif;
  position: relative;
  box-shadow: 1px -1px 5px 0px rgb(255 255 255 / 63%);
  -webkit-box-shadow: 1px -1px 5px 0px rgb(255 255 255 / 63%);
  -moz-box-shadow: 1px -1px 5px 0px rgb(255 255 255 / 63%);
  background: linear-gradient(27deg, #151515 5px, transparent 5px) 0 5px,
    linear-gradient(207deg, #151515 5px, transparent 5px) 10px 0px,
    linear-gradient(27deg, #222 5px, transparent 5px) 0px 10px,
    linear-gradient(207deg, #222 5px, transparent 5px) 10px 5px,
    linear-gradient(90deg, #1b1b1b 10px, transparent 10px),
    linear-gradient(
      #1d1d1d 25%,
      #1a1a1a 25%,
      #1a1a1a 50%,
      transparent 50%,
      transparent 75%,
      #242424 75%,
      #242424
    );
  background-color: #131313;
  background-size: 20px 20px;
}

.container button:hover {
  box-shadow: 2px 1px 5px 0px rgba(0, 0, 0, 0.6);
  -webkit-box-shadow: 2px 1px 5px 0px rgba(0, 0, 0, 0.6);
  -moz-box-shadow: 2px 1px 5px 0px rgba(0, 0, 0, 0.6);
}

.plenty {
  margin-top: 32px;
  text-align: center;

  color: gray;
}
.plenty a {
  text-decoration: none;
  color: #c8a791;
}
.card-bottom {
  margin-top: 8px;
}
.card-bottom-details {
  display: flex;
  font-size: 14px;
  text-align: center;
  width: 158px;
  margin-top: 0px;
  margin-left: 8px;
  justify-content: space-between;
}
.card-bottom-details div {
  line-height: 12px;
}
.card-details-title {
  color: #c8a791;
}
.name {
  color: #fafafa;
  padding-left: 12px;
  font-size: 18px;
}
.type {
  font-size: 22px;
  color: #d8c6ba;
  margin-top: 22px;
  font-weight: 600;
}
</style>