import { DataService } from "../axios";


async function hyphenInterestDetails(){
    return await DataService.get('hyphen/interests')
}
async function hyphensubscriptionsDetails(){
    return await DataService.get('hyphen/subscriptions')
}
async function hyphenCardDetails(code){
    return await DataService.get(`hyphen/get-hyphen-card/${code}`)
}



export{
    hyphenInterestDetails,
    hyphensubscriptionsDetails,
    hyphenCardDetails
}